<template>
  <div>
    <label
      v-if="label !== ''"
      class="md-form-label"
      style="display: block; margin-bottom: -10px; text-align: left; color: #aaa;"
      :class="{'asterisk': asterisk}"
      v-html="label"
    />
    <md-radio
      v-for="item in options"
      :key="item.value"
      v-model="result"
      :value="item.value"
      :disabled="disabled"
    >
      {{ item.label }}
    </md-radio>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number, Boolean],
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: '',
    },
    asterisk: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      result: null,
    };
  },
  watch: {
    value(val) {
      this.result = val;
    },
    result(value) {
      this.$emit('input', value);
    },
  },
  mounted() {
    this.result = this.value;
  },
};
</script>

<style scoped>
</style>
