<template>
  <div @click="launchFilePicker">
    <slot />
    <input
      ref="file"
      type="file"
      style="display: none"
      v-bind="$attrs"
      @change="fileSelected"
    >
  </div>
</template>

<script>
export default {
  props: {
    maxSize: {
      type: Number,
      default() {
        return 1024 * 2; // 2 MB
      },
    },
    value: {
      type: [Object, File, String],
      default: () => ({}),
    },
    tooBigErrorMsg: {
      type: String,
      default: 'The file max size is {maxSize} MB',
    },
    pdfType: {
      type: Boolean,
      default: false,
    },
    imageType: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      acceptedType: null,
    };
  },
  computed: {
    tooBigMsg() {
      if (this.tooBigErrorMsg.includes('{maxSize}')) {
        return this.tooBigErrorMsg.replace(
          '{maxSize}',
          (this.maxSize / 1024).toFixed(2),
        );
      }
      return this.tooBigErrorMsg;
    },
  },
  mounted() {
    if (this.imageType || this.pdfType) {
      this.acceptedType = this.imageType ? 'image' : 'pdf';
    }
  },
  methods: {
    launchFilePicker() {
      this.$refs.file.click();
    },
    resetInput() {
      this.$refs.file.value = null;
    },
    fileSelected(evt) {
      const { files } = evt.target;
      const { maxSize } = this;

      files.forEach((file) => {
        const size = file.size / 1024 / maxSize;

        // Check file type
        if (this.acceptedType) {
          if (this.acceptedType === 'image' && !file.type.match('image.*')) {
            // check whether the upload is an image
            this.$emit('error', `The file ${file.name} is not an image`);
            return;
          }
          if (this.acceptedType === 'pdf' && !file.type.match('application/pdf')) {
            // check whether the upload is an image
            this.$emit('error', `The file ${file.name} is not a PDF`);
            return;
          }
        }

        // check whether the size is greater than the size limit
        if (size > 1) {
          this.$emit('error', this.tooBigMsg);
        } else {
          this.$emit('input', file);
        }
      });
    },
  },
};
</script>
