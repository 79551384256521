<template>
  <div>
    <slide-y-down-transition>
      <md-icon
        v-show="failed"
        class="error"
        :style="{'padding-right': paddingR+'px'}"
      >
        close
      </md-icon>
    </slide-y-down-transition>
    <slide-y-down-transition>
      <md-icon
        v-show="passed"
        class="success"
        :style="{'padding-right': paddingR+'px'}"
      >
        done
      </md-icon>
    </slide-y-down-transition>
  </div>
</template>

<script>
import { SlideYDownTransition } from 'vue2-transitions';

export default {
  components: {
    SlideYDownTransition,
  },
  props: {
    failed: Boolean,
    passed: Boolean,
    paddingR: {
      type: Number,
      default: 0,
    },
  },
};
</script>
