<template>
  <div>
    <ValidationProvider
      v-if="!disabled"
      v-slot="{ passed, failed }"
      :name="name"
      :rules="rules"
    >
      <md-field
        :class="[
          { 'md-error': failed },
          { 'md-valid': passed },
          { 'md-form-group': icon !== '' }
        ]"
      >
        <md-icon v-if="icon">
          {{ icon }}
        </md-icon>
        <label
          :class="{'asterisk': asterisk}"
          v-html="label"
        />
        <md-input
          :value="value"
          :type="password ? 'password' : 'text'"
          @input="updateValue"
          @keydown.enter="$emit('keydownEnter')"
          @click="$emit('click', $event)"
        />

        <input-check-icons
          :failed="failed"
          :passed="passed"
        />
      </md-field>

      <p
        v-if="maxChars"
        style="margin: -0.75em 0 0 0; text-align: right; font-size: .7em;"
        :class="{'text-danger': (value ? value.length : 0) > maxChars}"
      >
        {{ maxChars - (value ? value.length : 0) }}/{{ maxChars }} characters
      </p>
    </ValidationProvider>
    <md-field
      v-else
      :class="[
        { 'md-form-group': icon !== '' }
      ]"
    >
      <md-icon v-if="icon">
        {{ icon }}
      </md-icon>
      <label>{{ label }}</label>
      <md-input
        disabled
        :value="value"
        type="text"
      />
    </md-field>
  </div>
</template>

<script>
import InputCheckIcons from '@/components/Inputs/InputCheckIcons';

export default {
  components: {
    InputCheckIcons,
  },
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    rules: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    asterisk: {
      type: Boolean,
      default: false,
    },
    password: {
      type: Boolean,
      default: false,
    },
    maxChars: {
      type: Number,
      default: null,
    },
  },
  methods: {
    updateValue(value) {
      if (this.maxChars && value.length > this.maxChars) {
        this.$emit('input', value.slice(0, this.maxChars));
      } else {
        this.$emit('input', value);
      }
    },
  },
};
</script>

<style scoped>
</style>
