<template>
  <div
    class="pb-3"
  >
    <ValidationProvider
      v-if="!disabled"
      v-slot="{ passed, failed }"
      :name="name"
      :rules="rules"
    >
      <md-field
        :class="[
          { 'md-error': failed },
          { 'md-valid': passed },
          { 'md-form-group': icon !== '' }
        ]"
      >
        <md-icon v-if="icon">
          {{ icon }}
        </md-icon>
        <label>{{ label }}</label>
        <md-textarea
          :value="value"
          :type="password ? 'password' : 'text'"
          @input="updateValue"
        />

        <input-check-icons
          :failed="failed"
          :passed="passed"
        />
      </md-field>
    </ValidationProvider>
    <md-field
      v-else
      :class="[
        { 'md-form-group': icon !== '' }
      ]"
    >
      <md-icon v-if="icon">
        {{ icon }}
      </md-icon>
      <label>{{ label }}</label>
      <md-textarea
        disabled
        :value="value"
        type="text"
      />
    </md-field>
  </div>
</template>

<script>
import InputCheckIcons from '@/components/Inputs/InputCheckIcons';

export default {
  components: {
    InputCheckIcons,
  },
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    rules: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    password: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    updateValue(value) {
      this.$emit('input', value);
    },
  },
};
</script>

<style scoped lang="scss">
.md-field.md-has-textarea:not(.md-autogrow) {
  >.md-icon {
    right: unset;
    left: 0;
  }
  .md-icon + label {
    left: 37px;
  }
}

.md-field>.md-icon~.md-textarea {
    margin-left: 21px;
}
</style>
