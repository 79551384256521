<template>
  <div class="form-group">
    <label v-if="label">{{ label }}</label>
    <div
      class="formHtml"
      :class="{'mt-1': label !== null}"
    >
      <trumbowyg
        v-model="resultHtml"
        :config="config"
        :disabled="disabled"
      />

      <p
        v-if="maxChars"
        style="margin: .25em 0 0 0; text-align: right; font-size: .7em;"
        :class="{'text-danger': (resultHtml ? resultHtml.length : 0) > maxChars}"
      >
        {{ maxChars - (resultHtml ? resultHtml.length : 0) }}/{{ maxChars }} characters
      </p>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    value: {
      type: [String],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    config: {
      type: Object,
      default: () => ({
        btns: [
          ['viewHTML'],
          ['undo', 'redo'], // Only supported in Blink browsers
          ['formatting'],
          ['strong', 'em', 'del'],
          ['superscript', 'subscript'],
          ['link'],
          ['insertImage'],
          ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
          ['unorderedList', 'orderedList'],
          ['horizontalRule'],
          ['removeformat'],
        ],
        semantic: {
          div: 'div', // Editor does nothing on div tags now
        },
      }),
    },
    maxChars: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    resultHtml: null,
  }),
  watch: {
    resultHtml(value) {
      this.$emit('input', value);
    },
  },
  mounted() {
    this.resultHtml = this.value;
  },
};
</script>

<style scoped>
</style>
