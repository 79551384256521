<template>
  <div>
    <ValidationProvider
      v-if="!disabled"
      v-slot="{ passed, failed }"
      :name="name"
      :rules="rules"
      class="w-100"
    >
      <md-progress-spinner
        v-if="loading"
        :md-diameter="30"
        :md-stroke="3"
        md-mode="indeterminate"
      />

      <div
        v-else
        class="d-flex gap-2"
      >
        <md-field
          :class="[
            { 'md-error': failed },
            { 'md-valid': passed },
            { 'md-form-group': !!icon }
          ]"
        >
          <md-icon v-if="icon">
            {{ icon }}
          </md-icon>
          <label
            :class="{'asterisk': asterisk}"
          >
            <span v-html="label" />
            <span
              v-if="helpText"
              class="pointer"
            >
              <md-icon class="md-sm">error</md-icon>
              <md-tooltip>{{ helpText }}</md-tooltip>
            </span>
          </label>
          <md-select
            :value="value"
            :name="name"
            :multiple="multiple"
            @input="updateValue"
          >
            <md-option
              v-for="item in options"
              :key="item.id ? item.id : item"
              :value="item.id !== 'undefined' ? item.id : item"
            >
              {{ item.name ? item.name : item }}
            </md-option>
          </md-select>

          <input-check-icons
            :failed="failed"
            :passed="passed"
            :padding-r="35"
          />
        </md-field>
        <slot name="button" />
      </div>
    </ValidationProvider>
    <div v-else>
      <md-field
        :class="[
          { 'md-form-group': icon }
        ]"
      >
        <md-icon v-if="icon">
          {{ icon }}
        </md-icon>
        <label>{{ label }}</label>
        <md-input
          :value="notEditableValue"
          type="text"
          disabled
        />
      </md-field>
    </div>
  </div>
</template>

<script>
import InputCheckIcons from '@/components/Inputs/InputCheckIcons';

export default {
  components: {
    InputCheckIcons,
  },
  props: {
    value: {
      type: [String, Number, Boolean, Array],
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    helpText: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    rules: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    asterisk: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    notEditableValue() {
      const aux = this.options.filter((x) => {
        if (this.value === true && x.id === 'true') return true;
        if (this.value === false && x.id === 'false') return true;

        return (x.id === this.value || (x.id === 'null' && this.value === null));
      });
      return aux.length ? aux[0].name : null;
    },
  },
  methods: {
    updateValue(value) {
      this.$emit('input', value);
      this.$emit('change', this.options.find((x) => x.id === value));
    },
  },
};
</script>

<style scoped>
  .md-form-group >>> .md-input.md-select-value {
    padding-left: 10px;
  }
</style>
