<template>
  <div>
    <ValidationProvider
      v-if="!disabled"
      v-slot="{ passed, failed }"
      :name="name"
      :rules="rules"
    >
      <md-field
        :class="[
          { 'md-error': failed },
          { 'md-valid': passed },
          { 'md-form-group': icon !== '' }
        ]"
      >
        <md-icon v-if="icon">
          {{ icon }}
        </md-icon>
        <label
          :class="{'asterisk': asterisk}"
          v-html="label"
        />

        <md-input
          v-if="currency"
          :value="displayValue"
          @input="updateValue"
          @focus="inputFocus"
          @blur="isInputActive = false"
        />
        <md-input
          v-else
          :value="result"
          type="number"
          @focus="inputFocus"
          @input="updateValue"
        />

        <input-check-icons
          :failed="failed"
          :passed="passed"
          :padding-r="35"
        />
      </md-field>
    </ValidationProvider>
    <md-field
      v-else
      :class="[
        { 'md-form-group': icon !== '' }
      ]"
    >
      <md-icon v-if="icon">
        {{ icon }}
      </md-icon>
      <label>{{ label }}</label>
      <md-input
        v-if="currency"
        v-currency
        disabled
        :value="displayValue"
      />
      <md-input
        v-else
        disabled
        :value="value"
        type="number"
      />
    </md-field>
  </div>
</template>

<script>
import InputCheckIcons from '@/components/Inputs/InputCheckIcons';

export default {
  components: {
    InputCheckIcons,
  },
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    rules: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    currency: {
      type: Boolean,
      default: false,
    },
    asterisk: {
      type: Boolean,
      default: false,
    },
    zeroMask: {
      type: String,
      default: 'N/A',
    },
    withZeroMask: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      result: this.value,
      isInputActive: false,
    };
  },
  computed: {
    displayValue() {
      if (this.isInputActive) {
        // Cursor is inside the input field. unformat display value for user
        return this.value;
      }

      // eslint-disable-next-line no-restricted-properties
      if (window.isNaN(this.value)) {
        return this.value.split(',').join('').replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
      }

      if (this.withZeroMask && (this.value === 0 || this.value === '0.00')) return this.zeroMask;

      // User is not modifying now. Format display value for user interface
      return parseFloat(this.value).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
    },
  },
  watch: {
    value(val) {
      this.result = val;
    },
  },
  methods: {
    updateValue(value) {
      // eslint-disable-next-line no-restricted-properties
      if (window.isNaN(value)) {
        this.$emit('input', parseFloat(value.split(',').join('')));
      } else {
        this.$emit('input', value);
      }
    },
    inputFocus() {
      // console.log('input focus');
      this.isInputActive = true;
      if (this.result === 0) { this.result = null; }
    },
  },
};
</script>

<style scoped>
input {
  width: 100%
}
</style>
