<template>
  <div class="form-color">
    <p class="form-color-label">
      {{ label }}
    </p>
    <input
      :value="value"
      type="color"
      @input="updateValue"
    >
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: 'Color',
    },
    value: {
      type: [String],
      default: '#456234',
    },
  },
  methods: {
    updateValue(e) {
      this.$emit('input', e.target.value);
    },
  },
};
</script>

<style scoped>
  .form-color-label {
    color: rgb(0 0 0 / 50%);
    margin: 0;
  }

 .form-color {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .form-color input {
    appearance: none;
    padding: 0;
    background-color: transparent;
    border: none;
  }

  .form-color + .form-color {
    margin-top: 6px;
  }

  .form-color.form-color-gradient > div {
    position: relative;
  }

  .form-color.form-color-gradient > div:after {
    content: '';
    position: absolute;
    height: 5px;
    width: 100%;
    bottom: 0;
    left: 0;
    background: linear-gradient(90deg, var(--color-1) 0%, var(--color-2) 100%);
  }
</style>
