<template>
  <div
    :id="itemId"
  >
    <ValidationProvider
      v-if="!disabled"
      v-slot="{ passed, failed }"
      :name="name"
      :rules="rules"
    >
      <md-field
        class="md-form-group md-autocomplete"
        :class="[
          { 'md-error': failed },
          { 'md-valid': passed },
          { 'withIcon': icon !== '' },
          { 'md-focused': focused },
          { 'md-has-value': !!result },
        ]"
      >
        <md-icon v-if="icon">
          {{ icon }}
        </md-icon>
        <label
          class="placeholder"
          :class="{'asterisk': asterisk}"
        >
          {{ label }}
        </label>
        <md-input
          v-model="search"
          type="text"
          :disabled="disabled"
          @focus="focused = true"
          @input="updateOptions"
        />

        <md-button
          v-if="search"
          class="md-just-icon md-sm md-round md-raised md-danger clear-button"
          @click="onReset"
        >
          <md-icon>close</md-icon>
          <md-tooltip>Clear</md-tooltip>
        </md-button>

        <div
          class="md-autocomplete-options"
          :class="{ disabled: !options.length}"
        >
          <span
            v-for="(opt, ix) of options"
            :key="ix"
            @click="onSelected(opt)"
          >
            {{ opt.name }}
          </span>

          <template v-if="!options.length">
            <span v-if="searched">
              No results found
            </span>
            <span v-else>
              Start typing to search...
            </span>
          </template>
        </div>
      </md-field>
    </ValidationProvider>
    <div v-else>
      <md-field :class="'md-form-group'">
        <md-icon v-if="icon !== undefined">
          {{ icon }}
        </md-icon>
        <label>{{ label }}</label>
        <md-input
          :value="result ? result.name : null"
          type="text"
          disabled
        />
      </md-field>
    </div>
  </div>
</template>

<script>
import debounce from '@/utils/debounce.js';

export default {
  props: {
    value: {
      type: [Object],
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    rules: {
      type: String,
      default: '',
    },
    minLen: {
      type: Number,
      default: 3,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    asterisk: {
      type: Boolean,
      default: false,
    },
    optionGetter: {
      type: Function,
      default: () => ({}),
    },
    options: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      itemId: null,
      search: null,
      focused: false,
      searched: false,
    };
  },
  computed: {
    result: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val?.id);
      },
    },
    optGetter() {
      return debounce(this.optionGetter, 500);
    },
  },
  watch: {
    result: {
      immediate: true,
      handler(val) {
        if (val) this.search = val.name;
        else this.search = null;
      },
    },
  },
  mounted() {
    this.fillId();
    window.addEventListener('click', this.clickOutsideListener);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.clickOutsideListener);
  },
  methods: {
    fillId() {
      this.itemId = Math.floor(Date.now() * Math.random());
    },
    onSelected(val) {
      this.result = val;
      this.search = val.name;
      this.focused = false;
    },
    onReset() {
      this.result = null;
      this.search = null;
      this.$emit('onReset');
    },
    onFocus() {
      this.focused = true;
    },
    updateOptions(value) {
      this.searched = true;

      if (!value || value === '') {
        this.search = null;
        this.optGetter(null);
      } else if (value.length >= this.minLen) {
        this.optGetter(value);
      }
    },
    clickOutsideListener(e) {
      const item = document.getElementById(this.itemId);

      if (!item.contains(e.target)) {
        this.focused = false;
      }
    },
  },
};
</script>
