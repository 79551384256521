<template>
  <label
    class="md-form-label FormCheckbox pointer"
    :class="{'asterisk': asterisk}"
  >
    <input
      class="pointer"
      type="checkbox"
      :name="name"
      :disabled="disabled"
      :checked="result"
      @change="onChange"
    >
    <slot />
    {{ label }}
  </label>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number, Boolean],
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: '',
    },
    asterisk: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      result: null,
    };
  },
  watch: {
    value(val) {
      this.result = !!val;
    },
    result(value) {
      this.$emit('input', value);
    },
  },
  mounted() {
    this.result = this.value;
  },
  methods: {
    onChange(e) {
      this.$emit('change', e.target.checked);
      e.target.checked = !e.target.checked;
    },
  },
};
</script>

<style scoped>
</style>
