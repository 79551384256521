var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":_vm.itemId}},[(!_vm.disabled)?_c('ValidationProvider',{attrs:{"name":_vm.name,"rules":_vm.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('md-field',{staticClass:"md-form-group md-autocomplete",class:[
        { 'md-error': failed },
        { 'md-valid': passed },
        { 'withIcon': _vm.icon !== '' },
        { 'md-focused': _vm.focused },
        { 'md-has-value': !!_vm.result } ]},[(_vm.icon)?_c('md-icon',[_vm._v(" "+_vm._s(_vm.icon)+" ")]):_vm._e(),_c('label',{staticClass:"placeholder",class:{'asterisk': _vm.asterisk}},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_c('md-input',{attrs:{"type":"text","disabled":_vm.disabled},on:{"focus":function($event){_vm.focused = true},"input":_vm.updateOptions},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.search)?_c('md-button',{staticClass:"md-just-icon md-sm md-round md-raised md-danger clear-button",on:{"click":_vm.onReset}},[_c('md-icon',[_vm._v("close")]),_c('md-tooltip',[_vm._v("Clear")])],1):_vm._e(),_c('div',{staticClass:"md-autocomplete-options",class:{ disabled: !_vm.options.length}},[_vm._l((_vm.options),function(opt,ix){return _c('span',{key:ix,on:{"click":function($event){return _vm.onSelected(opt)}}},[_vm._v(" "+_vm._s(opt.name)+" ")])}),(!_vm.options.length)?[(_vm.searched)?_c('span',[_vm._v(" No results found ")]):_c('span',[_vm._v(" Start typing to search... ")])]:_vm._e()],2)],1)]}}],null,false,3146177770)}):_c('div',[_c('md-field',{class:'md-form-group'},[(_vm.icon !== undefined)?_c('md-icon',[_vm._v(" "+_vm._s(_vm.icon)+" ")]):_vm._e(),_c('label',[_vm._v(_vm._s(_vm.label))]),_c('md-input',{attrs:{"value":_vm.result ? _vm.result.name : null,"type":"text","disabled":""}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }