<template>
  <div>
    <ValidationProvider
      v-if="!disabled"
      v-slot="{ passed, failed }"
      :name="name"
      :rules="rules"
    >
      <md-field
        :class="[
          { 'md-error': failed },
          { 'md-valid': passed },
          { 'md-form-group': icon !== '' }
        ]"
        class="time-picker"
      >
        <md-icon v-if="icon">
          {{ icon }}
        </md-icon>

        <label
          :class="{'asterisk': asterisk}"
          v-html="label"
        />
        <vue-timepicker
          :value="value"
          @change="changeHandler"
          @input="updateValue"
          @keydown.enter="$emit('keydownEnter')"
        />

        <input-check-icons
          :failed="failed"
          :passed="passed"
        />
      </md-field>
    </ValidationProvider>
    <md-field
      v-else
      :class="[
        { 'md-form-group': icon !== '' }
      ]"
    >
      <md-icon v-if="icon">
        {{ icon }}
      </md-icon>
      <label>{{ label }}</label>
      <md-input
        disabled
        :value="value"
        type="text"
      />
    </md-field>
  </div>
</template>

<script>
import InputCheckIcons from '@/components/Inputs/InputCheckIcons';
import VueTimepicker from 'vue2-timepicker';
import 'vue2-timepicker/dist/VueTimepicker.css';

export default {
  components: {
    InputCheckIcons,
    VueTimepicker,
  },
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    rules: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    asterisk: {
      type: Boolean,
      default: false,
    },
    password: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    changeHandler(value) {
      this.$emit('input', value.displayTime);
    },
  },
};
</script>

<style scoped>
</style>
